import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

import { useAccount } from '~/data/account/hooks/useAccount';
import { useUser } from '~/data/user/hooks/useUser';

export function useSentry(): null {
  const user = useUser();
  const account = useAccount();

  useEffect(() => {
    if (user?.data) {
      Sentry.setUser({ id: String(user?.data.id) });
    }

    if (account?.data.id) {
      Sentry.setContext('account', {
        id: String(account?.data.id),
        slug: account?.data.attributes.slug,
        features: account?.data.attributes.features,
      });
    }
  }, [user?.data.id, account?.data.id]);

  return null;
}
