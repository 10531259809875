import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';

import type { Window } from '~/components/root/.types/globalTypes';

export function useSegmentPageViewTracking() {
  const location = useLocation();

  useEffect(() => {
    const analytics = (window as unknown as Window).analytics;

    if (analytics) {
      analytics?.page();
    }
  }, [location]);
}
