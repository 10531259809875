import { init } from '@fullstory/browser';
import { useCallback } from 'react';

import { getPlanFromAccount } from '~/data/account/helpers/getPlanFromAccount';
import { useAccount } from '~/data/account/hooks/useAccount';
import { useEnv } from '~/data/env/hooks/useEnv';
import { useUser } from '~/data/user/hooks/useUser';

type FullStory = {
  setupFullStory: () => void;
};

export function useFullStory(): FullStory {
  const env = useEnv();
  const user = useUser();
  const account = useAccount();
  const plan = getPlanFromAccount(account?.data, account?.included);

  const setupFullStory = useCallback(() => {
    if (!env || !user || !account || !plan) {
      return null;
    }

    if (env.FULLSTORY_ORG_ID && plan.attributes.type === 'trial') {
      init({ orgId: env.FULLSTORY_ORG_ID });
    }
  }, [user, account, env, plan]);

  return { setupFullStory };
}
