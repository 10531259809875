import { Link } from '@remix-run/react';
import { useContext } from 'react';

import { Features } from '~/data/.types/api/generated/Account';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';
import { useAccountHasFeature } from '~/helpers/hooks/useAccountHasFeature';
import { useHydrated } from '~/helpers/hooks/useHydrated';

import { ModalContext } from '~/components/.shared/modal/ModalProvider';
import { CONTACT_SUPPORT_MODAL_ID } from '~/components/contact-support/modal/ContactSupportModal';

export function FiveHundredError() {
  const hydrated = useHydrated();
  const hasContactSupport = useAccountHasFeature(Features.ReleaseContactSupport);

  const { showModal } = useContext(ModalContext);

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img src={getSrcFromCDN('/images/illustrations/error.svg')} height="348" width="400" alt="" />
      <div className="flex flex-col items-center p-4">
        <h2 className="mt-3 text-neutral-20">Something went wrong</h2>
        <p className="my-3 text-center text-neutral-primary">
          We're having trouble performing your request and a bug report has been sent.{' '}
          {hasContactSupport ? (
            <Link
              to="/contact-support"
              onClick={(e) => {
                if (hydrated) {
                  e.preventDefault();
                  showModal(CONTACT_SUPPORT_MODAL_ID);
                }
              }}
            >
              Keep seeing this screen? Reach out to our support team
            </Link>
          ) : (
            <a href="mailto:contentworkflow@bynder.com">Keep seeing this screen? Reach out to our support team.</a>
          )}
        </p>
        <a className="button-base button-base-md button-primary hover:no-underline" href="">
          Reload the page
        </a>
      </div>
    </div>
  );
}
