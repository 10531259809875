import { ScrollLayout } from '@gathercontent/ui';
import { Links, Meta, Scripts, ScrollRestoration } from '@remix-run/react';
import type { ReactNode } from 'react';
import { useTypedLoaderData as useLoaderData } from 'remix-typedjson';
import type { loader } from '~/root';

import { getPlanFromAccount } from '~/data/account/helpers/getPlanFromAccount';
import { useAccount } from '~/data/account/hooks/useAccount';
import { useEnv } from '~/data/env/hooks/useEnv';
import { getPermissionsFromAccount } from '~/data/user/hooks/getPermissionsFromAccount';
import { useUser } from '~/data/user/hooks/useUser';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { ExternalScripts } from '~/components/root/scripts/ExternalScripts.client';

type LoaderDataWithSentry = ReturnType<typeof useLoaderData<typeof loader>> & {
  sentryTrace: string;
  sentryBaggage: string;
};

export function Document({ children, title }: { children: ReactNode; title?: string }) {
  const env = useEnv();
  const user = useUser();
  const account = useAccount();
  const loaderData = useLoaderData<LoaderDataWithSentry>();
  const plan = getPlanFromAccount(account?.data, account?.included);
  const features = new Map<string, boolean>();
  for (let i = 0; i < (account?.data?.attributes?.features?.length || 0); i++) {
    features.set(account?.data?.attributes?.features[i] || '', true);
  }
  const userPermissions = getPermissionsFromAccount(account);
  const permissions = new Map<string, boolean>();

  if (userPermissions) {
    userPermissions.forEach((value) => {
      // Data massaging to match what CodeIgnitor does when creating the segment/appcues data
      value = value.replace('user_', '').replace('GenerateAIContent', 'CanUseAI');

      permissions.set(`user_${value}`, true);
    });
  }

  const appcuesData = new Map<string, unknown>([
    ...Object.entries({
      ...user?.data.attributes,
      company_plan: plan?.attributes.name,
      createdAt: user?.data.attributes.createdAt,
    }),
    ...permissions.entries(),
    ...features.entries(),
  ]);

  // Used to render the external scripts only on the browser and not on the server
  const onClient = useHydrated();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="sentry-trace" content={loaderData?.sentryTrace} />
        <meta name="baggage" content={loaderData?.sentryBaggage} />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {account?.data?.attributes?.slug !== 'app' && <meta name="robots" content="noindex" />}
        {title && <title>{title}</title>}
        <Meta />
        <Links />
        {onClient && <ExternalScripts location="head" />}
      </head>
      <body id="app">
        <ScrollLayout>{children}</ScrollLayout>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(env)}; window.user = ${JSON.stringify(
              user?.data,
            )}; window.account = ${JSON.stringify(account?.data)}; window.userHash = ${JSON.stringify(
              loaderData?.userHash,
            )}; window.appcuesData = ${JSON.stringify(Object.fromEntries(appcuesData))}`,
          }}
        />
        <Scripts />
        {onClient && <ExternalScripts location="body" />}
      </body>
    </html>
  );
}
