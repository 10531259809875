import { ButtonPrimary } from '@gathercontent/ui';
import { Link } from '@remix-run/react';
import { useContext } from 'react';

import { Features } from '~/data/.types/api/generated/Account';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';
import { useAccountHasFeature } from '~/helpers/hooks/useAccountHasFeature';
import { useHydrated } from '~/helpers/hooks/useHydrated';

import { ModalContext } from '~/components/.shared/modal/ModalProvider';
import { CONTACT_SUPPORT_MODAL_ID } from '~/components/contact-support/modal/ContactSupportModal';

export function NotFoundError() {
  const hydrated = useHydrated();
  const hasContactSupport = useAccountHasFeature(Features.ReleaseContactSupport);

  const { showModal } = useContext(ModalContext);

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img src={getSrcFromCDN('/images/illustrations/error.svg')} height="348" width="400" alt="" />
      <div className="flex flex-col items-center text-center p-4">
        <h2 className="mt-3 text-neutral-20">Page not found</h2>
        <p className="mt-3 text-neutral-primary">Embarassingly, we can't find the page you requested.</p>
        <p className="mb-3 text-neutral-primary">
          {hasContactSupport ? (
            <>
              Please{' '}
              <Link
                to="/contact-support"
                onClick={(e) => {
                  if (hydrated) {
                    e.preventDefault();
                    showModal(CONTACT_SUPPORT_MODAL_ID);
                  }
                }}
              >
                get in touch
              </Link>{' '}
              so we can fix the issue.
            </>
          ) : (
            <>
              Please <a href="mailto:contentworkflow@bynder.com">get in touch</a> so we can fix the issue.
            </>
          )}
        </p>
        <ButtonPrimary onClick={() => history.back()}>Go back</ButtonPrimary>
      </div>
    </div>
  );
}
