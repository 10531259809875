import { useMatches } from '@remix-run/react';
import { useEffect } from 'react';

export type ScriptType = {
  id: string;
  location: 'head' | 'body';
  src: string;
  attributes?: Record<string, string>;
  dataCookieConsent?: 'statistics' | 'marketing' | 'preference' | null;
};

export type ScriptsFunction = () => ScriptType[];

export type ExternalScriptsProps = {
  location: 'head' | 'body';
};

export function ExternalScripts(props: ExternalScriptsProps) {
  const matches = useMatches();

  const externalScripts = matches.flatMap((m) => {
    if (m.handle && typeof m.handle === 'object' && 'scripts' in m.handle && typeof m.handle.scripts === 'function') {
      const scripts = m.handle.scripts();
      return scripts.filter((s: ScriptType) => props.location === s.location);
    }

    return [];
  });

  // Having to manually add the script tags to the DOM because if done using react components
  // the script doesn't load
  // @see https://codingshower.com/adding-and-executing-script-tags-in-react-render/

  useEffect(() => {
    externalScripts.forEach((script) => {
      const externalScript = document.createElement('script');
      externalScript.src = script.src;
      externalScript.id = script.id;
      externalScript.async = true;

      const mountingPoint = props.location === 'head' ? document.head : document.body;
      mountingPoint.append(externalScript);
    });
  }, []);

  return <></>;
}
