import { ScrollLayout } from '@gathercontent/ui';
import { Links, Meta, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import type { ReactElement } from 'react';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { FiveHundredError } from '~/components/.shared/errors/FiveHundredError';
import { ForbiddenError } from '~/components/.shared/errors/ForbiddenError';
import { NotFoundError } from '~/components/.shared/errors/NotFoundError';

import { ExternalScripts } from './scripts/ExternalScripts.client';

type ErrorDocumentProps = {
  title: string;
  onClient: boolean;
  children: ReactElement;
};

function ErrorDocument({ title, onClient, children }: ErrorDocumentProps) {
  return (
    <html lang={'en-GB'}>
      <head>
        <title>{title}</title>
        <Meta />
        <Links />
        {onClient && <ExternalScripts location="head" />}
      </head>
      <body id="app">
        <ScrollLayout>{children}</ScrollLayout>
        <ScrollRestoration />
        <Scripts />
        {onClient && <ExternalScripts location="body" />}
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const onClient = useHydrated();
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    let component: ReactElement | null = null;

    switch (error.status) {
      case 403:
        component = (
          <div className="h-screen">
            <ForbiddenError />
          </div>
        );
        break;
      case 404:
        component = (
          <div className="h-screen">
            <NotFoundError />
          </div>
        );
        break;
      case 429:
        component = (
          <div>
            <p>Oops! Looks like you tried to request a page too many times.</p>
          </div>
        );
        break;

      default:
        throw new Error(error.data || error.statusText);
    }

    return (
      <ErrorDocument title={`${error.status} ${error.statusText}`} onClient={onClient}>
        {component}
      </ErrorDocument>
    );
  }

  return (
    <ErrorDocument title="Something went wrong" onClient={onClient}>
      <div className="h-screen">
        <FiveHundredError />
      </div>
    </ErrorDocument>
  );
}
