import { type Response } from 'ts-json-api';

import type { AccountApiSchema } from '~/data/.types/api/generated/Account';
import type { PermissionApiSchema } from '~/data/.types/api/generated/Permission';
import { ResourceTypes } from '~/data/.types/apiTypes';

export function getPermissionsFromAccount(account: Response<AccountApiSchema> | null): string[] | false {
  if (!account?.data) {
    return false;
  }

  return (
    account.included
      ?.filter((i) => i.type === ResourceTypes.Permission)
      .map((i): PermissionApiSchema => i as PermissionApiSchema)
      .map((i: PermissionApiSchema): string => i.attributes?.name || '')
      .filter((permission) => permission !== null) || []
  );
}
