import { ButtonPrimary } from '@gathercontent/ui';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';

export function ForbiddenError() {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img src={getSrcFromCDN('/images/illustrations/error.svg')} height="348" width="400" alt="" />
      <div className="flex flex-col items-center text-center p-4">
        <h2 className="my-2 text-neutral-20">Access denied</h2>
        <p className="text-neutral-primary">You don't have the correct permissions to see this page.</p>
        <p className="mb-3 text-neutral-primary">Talk to your account admin to resolve this.</p>
        <ButtonPrimary onClick={() => history.back()}>Go back</ButtonPrimary>
      </div>
    </div>
  );
}
